import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {BrowserRouter as Router} from "react-router-dom";
import {register} from "./serviceWorker";
import {ConfigProvider} from "antd";
import {HelmetProvider} from "react-helmet-async";
import {FacebookProvider} from "react-facebook";
import {CookiesProvider} from "react-cookie";

ReactDOM.render(
  <Router>
    <ConfigProvider componentSize="large">
      <HelmetProvider>
        <FacebookProvider appId="258983288724950">
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </FacebookProvider>
      </HelmetProvider>
    </ConfigProvider>
  </Router>,
  document.getElementById("root"),
);

register({
  onUpdate() {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  },
});
