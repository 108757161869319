import React from "react";
import {Button} from "antd";
import "./CookiesAcceptReject.scss";
import Analytics from "../Analytics/Analytics";
import {useCookies} from "react-cookie";

const CookiesAcceptReject: React.FC = () => {
  const [cookies, setCookie] = useCookies(["cookieActioned"]);

  const handleAccept = () => {
    setCookie("cookieActioned", "accepted", {path: "/"});
  };
  const handleReject = () => {
    setCookie("cookieActioned", "rejected", {path: "/"});
  };

  return (
    <>
      {cookies.cookieActioned === undefined ? (
        <div className="cookieWrapper">
          <p>
            Cookies help us to improve our site and services. By using our
            website you agree that we can use cookies. Read more about our
            Privacy Policy and visit the following link:
            <a href="https://keralasamajam-hamburg.com/privacy-policy">
              Privacy Policy
            </a>
          </p>

          <div className="buttonsWrapper">
            <Button onClick={handleReject}>Reject</Button>
            <Button type="primary" onClick={handleAccept}>
              Accept
            </Button>
          </div>
        </div>
      ) : null}
      {cookies.cookieActioned === "accepted" ? <Analytics /> : null}
    </>
  );
};
export default CookiesAcceptReject;
