import React from "react";
import {Helmet} from "react-helmet-async";

const Analytics: React.FC = () => {
  return (
    <Helmet>
      <script type="text/javascript" src="firbase-init.js" />
    </Helmet>
  );
};
export default Analytics;
