import {Divider} from "antd";
import React from "react";
import "./Footer.scss";
import {Link} from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className={"second-row"}>
        <div className="copy-right">
          <Link to={"/imprint"}>
            <h4 className="title">Imprint</h4>
          </Link>
          {" | "}
          <Link to={"/privacy-policy"}>
            <h4 className="title">Privacy Policy</h4>
          </Link>
          {" | "}
          <Link to={"/terms-of-service"}>
            <h4 className="title">Terms of Service</h4>
          </Link>
          <br />
          <h4>Copyright &copy; 2022 : Keralasamajam Hamburg e.V.</h4>
        </div>
        <Divider type="vertical" />
        <div className="builder">
          <h4>built by: </h4>
          <a
            href="https://simi-swaminathan.web.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h4 className="title">simi.web </h4>
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
