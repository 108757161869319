import React from "react";
import {
  HomeOutlined,
  TeamOutlined,
  MessageOutlined,
  BulbOutlined,
  CameraOutlined,
  CompassOutlined,
  VideoCameraOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import NavLink from "./components/NavLink/NavLink";

export const NAV_ROUTES = [
  {
    links: [
      {
        path: <NavLink heading={"Home"} urlPath={"/"} />,
        icon: <HomeOutlined />,
        route: "/",
      },
    ],
  },
  {
    links: [
      {
        path: <NavLink heading={"About"} urlPath={"/about"} />,
        icon: <BulbOutlined />,
        route: "/about",
      },
    ],
  },
  {
    links: [
      {
        path: <NavLink heading={"Membership"} urlPath={"/membership"} />,
        icon: <UserAddOutlined />,
        route: "/membership",
      },
    ],
  },
  {
    links: [
      {
        path: <NavLink heading={"Gallery"} urlPath={"/gallery"} />,
        icon: <CameraOutlined />,
        route: "/gallery",
      },
    ],
  },
  {
    links: [
      {
        path: <NavLink heading={"Events"} urlPath={"/events"} />,
        icon: <VideoCameraOutlined />,
        route: "/events",
      },
    ],
  },
  {
    links: [
      {
        path: <NavLink heading={"Team"} urlPath={"/team"} />,
        icon: <TeamOutlined />,
        route: "/team",
      },
    ],
  },
  {
    links: [
      {
        path: <NavLink heading={"Resources"} urlPath={"/resources"} />,
        icon: <CompassOutlined />,
        route: "/resources",
      },
    ],
  },
  {
    links: [
      {
        path: <NavLink heading={"Contact"} urlPath={"/contact"} />,
        icon: <MessageOutlined />,
        route: "/contact",
      },
    ],
  },
];
