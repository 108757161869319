import {useLayoutEffect, useState} from "react";

export type IMediaQuery = Array<string>;

export type IMatchedMedia = Array<boolean>;

export const useMatchMedia = (queries: IMediaQuery): IMatchedMedia => {
  const mediaQueryLists = queries.map(q => window.matchMedia(q));
  const getValue = (): IMatchedMedia => {
    // Return the value for the given queries
    const matchedQueries = mediaQueryLists.map(mql => mql.matches);

    return matchedQueries;
  };

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useLayoutEffect(() => {
    // Event listener callback
    // Note: By defining getValue outside of useEffect we ensure that it has ...
    // ... current values of hook args (as this hook only runs on mount/dismount).
    const handler = (): void => setValue(getValue);
    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach(mql => mql.addListener(handler));
    // Remove listeners on cleanup
    return (): void =>
      mediaQueryLists.forEach(mql => mql.removeListener(handler));
  }, []);

  return value;
};

export const useIsDesktop = (): boolean => {
  const [isDesktop] = useMatchMedia(["(min-width: 768px)"]);

  return isDesktop;
};
