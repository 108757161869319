import {UpOutlined} from "@ant-design/icons";
import {BackTop} from "antd";
import React from "react";
import "./App.less";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Content from "./pages/Content/Content";
import CookiesAcceptReject from "./components/CookiesAcceptReject/CookiesAcceptReject";

const App: React.FC = () => {
  return (
    <div className={"app"}>
      <Navbar />
      <Content />
      <Footer />
      <CookiesAcceptReject />
      <BackTop>
        <UpOutlined />
      </BackTop>
    </div>
  );
};
export default App;
