import React, {useEffect, Suspense, lazy} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import styles from "./Content.module.scss";
import Spinner from "../../components/Spinner/Spinner";

const About = lazy(() => import("../About/About"));
const Home = lazy(() => import("../Home/Home"));
const Membership = lazy(() => import("../Membership/Membership"));
const BoardMembers = lazy(() => import("../Team/Team"));
const Resources = lazy(() => import("../Resources/Resources"));
const Imprint = lazy(() => import("../privacy/Imprint/Imprint"));
const PrivacyPolicy = lazy(
  () => import("../privacy/PrivacyPolicy/PrivacyPolicy"),
);
const TermsOfService = lazy(
  () => import("../privacy/TermsOfService/TermsOfService"),
);
const Register = lazy(() => import("../../components/Register/Register"));
const Announcements = lazy(() => import("../Announcements/Announcements"));
const DetailedContent = lazy(
  () => import("../DetailedContent/DetailedContent"),
);
const Restaurants = lazy(() => import("../Restaurants/Restaurants"));
const RentalApartments = lazy(
  () => import("../RentalApartments/RentalApartments"),
);
const Contact = lazy(() => import("../Contact/Contact"));
const Gallery = lazy(() => import("../Gallery/Gallery"));
const NotFound = lazy(() => import("../NotFound/NotFound"));
const News = lazy(() => import("../News/News"));
const Events = lazy(() => import("../KSHEvents/Events"));

const Content: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    window["scrollTo"]({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return (
    <div className={styles.content}>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact={true} path="/">
            <Home />
          </Route>
          <Route path="/about" component={About}></Route>
          <Route path="/membership" component={Membership}></Route>
          <Route path="/team" component={BoardMembers}></Route>
          <Route path="/resources" component={Resources}></Route>
          <Route path="/rental-apartments">
            <RentalApartments />
          </Route>
          <Route path="/gallery">
            <Gallery />
          </Route>
          <Route path="/restaurants">
            <Restaurants />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/news">
            <News />
          </Route>
          <Route path="/announcements">
            <Announcements />
          </Route>
          <Route path="/events">
            <Events />
          </Route>
          <Route path="/details/:id">
            <DetailedContent />
          </Route>
          <Route path="/imprint">
            <Imprint />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/terms-of-service">
            <TermsOfService />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
};
export default Content;
