import {MenuOutlined} from "@ant-design/icons";
import {Drawer, Menu} from "antd";
import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import ksh from "../../assets/images/ksh.png";
import {NAV_ROUTES} from "../../routes";
import Header from "../Header/Header";
import {useIsDesktop} from "../../hooks/useMatchMedia";
import "./Navbar.scss";
import {ReactComponent as InstagramLogo} from "../../assets/svgs/instagram_icon.svg";
import {ReactComponent as FacebookLogo} from "../../assets/svgs/facebook_icon.svg";
import {ReactComponent as EmailLogo} from "../../assets/svgs/email_icon.svg";

const Navbar: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const currentLocation = useLocation();

  const showDrawer = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const menuLinks = NAV_ROUTES.map(element => {
    return (
      <Menu.Item key={element.links[0].route} icon={element.links[0].icon}>
        {element.links[0].path}
      </Menu.Item>
    );
  });
  const isDesktop = useIsDesktop();

  return (
    <div className={"navbar"}>
      <Header />

      {isDesktop ? (
        <Menu mode="horizontal" selectedKeys={[currentLocation.pathname]}>
          {menuLinks}
        </Menu>
      ) : (
        <MenuOutlined onClick={showDrawer} />
      )}

      <Drawer
        title={<img src={ksh} style={{width: "90px"}} alt="" />}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={drawerVisible}
        width={"280px"}
        style={{textAlign: "center", backdropFilter: "blur(5px)"}}
        headerStyle={{boxShadow: "0px 0px 20px 0px #e2e2e2"}}
      >
        <Menu
          mode="inline"
          onClick={onClose}
          selectedKeys={[currentLocation.pathname]}
        >
          {menuLinks}
        </Menu>
      </Drawer>
      <div className="social-media">
        <div className="sites">
          <a
            href="https://www.instagram.com/ksh_hamburg/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramLogo />
          </a>
        </div>
        <div className="sites">
          <a
            href="https://www.facebook.com/KeralaSamajamHamburg/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookLogo />
          </a>
        </div>
        <div className="sites">
          <a
            href="mailto:keralasamajam.hamburg@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <EmailLogo />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
